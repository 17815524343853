import React from 'react'
import { Link } from "gatsby"

import Layout3 from "../components/layout3"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"


import Footer from "../components/footer"

import ContactSection from "../components/home-page/contactSection"


import '../scss/style.scss'

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Breadcrumb from "react-bootstrap/Breadcrumb"

import Blog3Img from "../images/Mailsculpt-Image-3.jpg"

import masterHeaderImg from "../images/Masterclass-header.jpg"
import masterHeader2Img from "../images/Demystifying-economics-header.jpg"
import hubspotTriImg from "../images/hubspotTriangle.png"
import grammarlyImg from "../images/grammarly.jpg"
import masterBodyImg from "../images/Masterclass-body.jpg"
import hubspotCTAImg from "../images/hubspot-cta.png"
import headspaceCTAImg from "../images/headspace-cta.png"
import hubspotFootImg from "../images/hubspot-footer.png"
import allBirdsFootImg from "../images/allBirds-footer.png"


const AnatomyOfAHighConvertingEmailDesign = () => (

    <Layout3 >
        <SEO
            title="Anatomy of a high-converting email design"
            description="Email design is essential to convey the information in a clear, readable way so the subscriber can move on to take action. Read this post to discover the three design elements of a high-converting email with examples, tips, and checklist."
            author="Mail Sculpt"
        />

        <Helmet>
            <meta property="og:url" content="https://www.mailsculpt.com/anatomy-of-a-high-converting-email-design/" />
            <meta property="og:image" content="https://www.mailsculpt.com/static/Mailsculpt-Image-3-fb8789d5d12db6a023750003f8a94be6.jpg" />
        </Helmet>


        <Container style={{ marginTop: "120px" }}>

            <Row>
                <Col>
                    <Breadcrumb style={{ fontFamily: "Roboto", backgroundColor: "none" }}>
                        <Breadcrumb.Item><Link to="/" >Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/blogs" >Blogs</Link></Breadcrumb.Item>
                        {/* <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                Library
  </Breadcrumb.Item> */}
                        <Breadcrumb.Item active>Anatomy of a high-converting email design</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
        </Container>

        <Container style={{ maxWidth: "800px" }}>
            <Row>
                <Col>
                    <img src={Blog3Img} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p style={{ fontFamily: "Roboto" }}>17th August, 2020</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1 style={{ fontFamily: "Roboto" }}>Anatomy of a High-Converting Email Design</h1>
                </Col>
            </Row>




            <Row>
                <Col>
                    <p style={{ fontFamily: "Roboto" }}>Designing a high-converting email is not about using bold colors, new fonts, and slapping some Unsplash images. Good aesthetics help capture the reader’s attention but, science and structure behind it will keep their attention until they read the full email. </p>
                    <p style={{ fontFamily: "Roboto" }}>So this post is about fundamentals of how you can create a high-converting, professional-looking email design with any brand, image, or colors. You will also find some examples for you to take inspiration.
</p>
                    <p style={{ fontFamily: "Roboto" }}>An email has three core sections: The header, the body, and the footer. Each section has it’s own purpose and should be designed to achieve that purpose.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Let’s read on to find more about the anatomy of a high-converting email design.
</p>

                </Col>
            </Row>


            <Row>
                <Col>
                    <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>The Header
</h2>
                    <p style={{ fontFamily: "Roboto" }}>The header’s goal is to remind the reader about the brand through the logo and the same brand colors in each email. Some emails don’t use the logo but a bar to highlight the start of the email. The point is, whatever you choose, it should remind the reader about the brand, and it should be consistent across all your emails.
</p>
                    <p style={{ fontFamily: "Roboto" }}>In addition to the visuals, don’t forget about subject lines & the sender’s info. The subject will decide whether someone opens your emails, and the sender’s info will influence whether your email goes to the inbox or the spam box.
</p>

                </Col>
            </Row>




            <Row className="text-center">
                <Col>
                    <img src={masterHeader2Img} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                    <img src={masterHeaderImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                    <a href="https://www.masterclass.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.masterclass.com</a>
                </Col>
            </Row>



            <Row>
                <Col>

                    <p style={{ fontFamily: "Roboto" }}>In these two examples from Masterclass, you will notice a thin bar and a big visual in their header. They have been consistent with this format. That helps build strong brand-recall because someone can instantly recognize the email sender and connect quickly with the topic.
</p>

                </Col>
            </Row>


            <Row>
                <Col>
                    <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>The body
</h2>
                    <p style={{ fontFamily: "Roboto" }}>The purpose of your email’s main body is to tell readers what you are talking about and get them to take action by clicking the buttons or links.
</p>
                    <p style={{ fontFamily: "Roboto" }}>An excellent copy is essential in the body, but a good copy won’t do much if it’s not presented in a way that’s easy to read. With so little attention span of the reader, your design has to focus & naturally lead to the CTA ( call-to-action).
</p>
                    <p style={{ fontFamily: "Roboto" }}>This can be done by using an inverted pyramid design: a framework to put together elements in a way that they work together to get the reader to take action.
</p>
                    <p style={{ fontFamily: "Roboto" }}>HubSpot email uses an inverted pyramid design to get people to click on ‘Learn More.’
</p>

                </Col>
            </Row>

            <Row className="text-center">
                <Col>
                    <img src={hubspotTriImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                    <a href="https://www.hubspot.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.hubspot.com</a>
                </Col>
            </Row>



            <Row>
                <Col>



                    <p style={{ fontFamily: "Roboto" }}>If you have more to say in your email, create different sections. Use different colors or break your text with some images in between.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Here is how Grammarly uses images and text to create different sections. This way, it doesn’t overwhelm the reader.
</p>


                </Col>
            </Row>

            <Row className="text-center">
                <Col>
                    <img src={grammarlyImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                    <a href="https://www.grammarly.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.grammarly.com</a>
                </Col>
            </Row>



            <Row>
                <Col>




                    <p style={{ fontFamily: "Roboto" }}>Even your text hierarchy plays an important role here. Use bigger/bolder text for the headline n then shorter for the subheading and lightest for your description text. Please stick to one font and vary its weight to highlight it’s chronology.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Here is how Masterclass emails use this concept beautifully.
</p>

                </Col>
            </Row>

            <Row className="text-center">
                <Col>
                    <img src={masterBodyImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                    <a href="https://www.masterclass.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.masterclass.com</a>
                </Col>
            </Row>



            <Row>
                <Col>


                    <p style={{ fontFamily: "Roboto" }}>One of the essential elements of your body is your CTAs - Call-to-Actions. These can be buttons or links that will make people take the desired action.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Make them distinct by choosing your brand color over a contrasting background. Use ample negative space around it, so it’s visible, and it’s easy to click on it when viewing the email on mobile.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Here is how HubSpot makes their CTAs stand out with distinct color and white space around it.
</p>

                </Col>
            </Row>

            <Row className="text-center">
                <Col>
                    <img src={hubspotCTAImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                    <a href="https://www.hubspot.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.hubspot.com</a>
                </Col>
            </Row>



            <Row>
                <Col>


                    <p style={{ fontFamily: "Roboto" }}>Here is another example from Headspace emails. The CTA is in brand color ( orange), the background is white, and there is ample negative space around it.
</p>
                </Col>
            </Row>



            <Row className="text-center">
                <Col>
                    <img src={headspaceCTAImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                    <a href="https://www.headspace.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.headspace.com</a>
                </Col>
            </Row>






            <Row>
                <Col>
                    <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>The Footer
</h2>
                    <p style={{ fontFamily: "Roboto" }}>The footer is the most neglected part of the email, but if done right, it a great way to end your email with an impact.
</p>
                    <p style={{ fontFamily: "Roboto" }}>It’s a place where people can find contact information, download your apps, manage their email preferences, and, most importantly, unsubscribe ( rather than mark you spam).
</p>
                    <p style={{ fontFamily: "Roboto" }}>Since HubSpot is a CRM platform, they focus on ‘Sales,’ ‘Account’ in their footer, and then tell you their contact info and links to unsubscribe, etc. All the links are arranged chronologically from most valuable to the least useful info.
</p>


                </Col>
            </Row>



            <Row className="text-center">
                <Col>
                    <img src={hubspotFootImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                    <a href="https://www.hubspot.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.hubspot.com</a>
                </Col>
            </Row>


            <Row>
                <Col>





                    <p style={{ fontFamily: "Roboto" }}>This footer from Allbirds has its social media link because that’s where their audience engages with them, and this will help build their social clout, which is vital for a consumer brand.
</p>

                </Col>
            </Row>



            <Row className="text-center">
                <Col>
                    <img src={allBirdsFootImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                    <a href="https://www.allbirds.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.allbirds.com</a>
                </Col>
            </Row>


            <Row>
                <Col>


                    <p style={{ fontFamily: "Roboto" }}>The design n copy will change according to your purpose, but few things which remain constant are: <br />
                    Clear separation between body and footer: Hubspot uses a different &amp; contrasting color for their footer. Allbirds separate it with their catchphrase, in every email.
                    <br />
                    Link to unsubscribe: This is the traditional location where the unsubscribe button is expected to be found. So keep it there, always.
</p>
                    <p style={{ fontFamily: "Roboto" }}>After implementing all the correct design elements, don’t forget to make your emails responsive. Meaning, it should adapt itself to the device it is being viewed on.
</p>
                </Col>
            </Row>


            <Row>
                <Col>
                    <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>Checklist
</h2>
                    <p style={{ fontFamily: "Roboto" }}>Here is a checklist for you to go over before you hit send on an email
</p>
                    <ul>
                        <li style={{ fontFamily: "Roboto" }}>Logo &amp; brand colors are on point.</li>
                        <li style={{ fontFamily: "Roboto" }}>Width is between 450-600px.
</li>
                        <li style={{ fontFamily: "Roboto" }}>Images and text are aligned as expected.
</li>
                        <li style={{ fontFamily: "Roboto" }}>Text font &amp; weight create a clear hierarchy.
</li>
                        <li style={{ fontFamily: "Roboto" }}>Images are not pixelating.
</li>
                        <li style={{ fontFamily: "Roboto" }}>Footer is present in a different section.
</li>
                        <li style={{ fontFamily: "Roboto" }}>The Unsubscribe link is visible in the footer.
</li>
                        <li style={{ fontFamily: "Roboto" }}>Email is mobile responsive.
</li>
                        <li style={{ fontFamily: "Roboto" }}>Call to Action is distinct and visible.
</li>
                    </ul>
                </Col>
            </Row>


            <Row>
                <Col>
                    <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>What’s next?
</h2>
                    <p style={{ fontFamily: "Roboto" }}>I hope this post helps you craft high-converting emails for your brand.
</p>
                    <p style={{ fontFamily: "Roboto" }}>To start, you can choose templates available on various platforms. Although those will not offer flexibility to get the exact look you want.
</p>
                    <p style={{ fontFamily: "Roboto" }}>The next option is to create these using HTML & CSS that gives you all the possibilities you want right from the structure, fonts, alignment, etc. You can get the best of all the good design principles. Yes, that will require you to focus away from your core business.
</p>
                    <p style={{ fontFamily: "Roboto" }}>If that’s too tedious and you wish professionals can take care of it, you should check out Mailsculpt. We specialize in creating responsive, high-converting, professional-looking emails. You can focus on your core business &&amp; let the specialists take care of your emails. Talk with one of our dedicated email specialists <a href="javascript:$zopim.livechat.window.show();" style={{ fontWeight: "bold" }}>here</a>.
</p>
                </Col>
            </Row>


        </Container >

        <ContactSection />

        <Footer />




    </Layout3 >

)

export default AnatomyOfAHighConvertingEmailDesign